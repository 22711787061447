import { Logger } from '~/helpers/logger';
import { BillingCartAddress, Maybe, SetBillingAddressOnCartInput } from '~/modules/GraphQL/types';

export const saveBillingAddressCommand = {
  execute: async (context, cartId, billingDetails, customQuery, customHeaders): Promise<Maybe<BillingCartAddress>> => {
    const {
      apartment,
      neighborhood,
      extra,
      sameAsShipping,
      customerAddressId,
      ...address
    } = billingDetails;

    if (address && (address.region_name || address.region_name === '')) {
      delete address.region_name;
    }

    const billingData = customerAddressId
      ? ({ customer_address_id: customerAddressId })
      : ({
        address: {
          ...address,
          street: [address.street, apartment, neighborhood, extra].filter(Boolean),
        },
        same_as_shipping: sameAsShipping,
      });

    const setBillingAddressOnCartInput: SetBillingAddressOnCartInput = {
      cart_id: cartId,
      billing_address: billingData,
    };

    const { data, errors } = await context.$vsf.$magento.api.setBillingAddressOnCart(
      setBillingAddressOnCartInput,
      customQuery,
      customHeaders,
    );

    Logger.debug('[setBillingAddressOnCart Result]:', { data, errors });
    if ((errors && errors.length > 0) || !data) {
      throw new Error('Error with set billing address');
    }

    return data?.setBillingAddressOnCart?.cart?.billing_address ?? null;
  },
};
