export default `
query categoryList {
  categories {
    items {
      children {
        include_in_menu
        level
        name
        position
        uid
        url_key
        url_path
        url_suffix
        children {
          include_in_menu
          level
          name
          position
          uid
          url_key
          url_path
          url_suffix
          children {
            include_in_menu
            level
            name
            position
            uid
            url_key
            url_path
            url_suffix
            children {
              include_in_menu
              level
              name
              position
              uid
              url_key
              url_path
              url_suffix
            }
          }
        }
      }
    }
  }
}

`;
