import { Ref, ref, useContext } from '@nuxtjs/composition-api';

import { Logger } from '~/helpers/logger';
import categoryPageInfoGql from '~/modules/catalog/category/composables/useCategoryPageInfo/categoryPageInfo.gql';
import displayModesEnum from '~/modules/catalog/category/enums/displayModesEnum';

import type { UseCategoryPageInfoErrors, UseCategoryPageInfoInterface, UseCategoryPageInfoMetaParamsInput } from './useCategoryPageInfo';
/**
 * @public
 *
 * Allows loading categories from Magento API. It
 * is commonly used in navigation menus, and provides the load function and
 * refs for the categories, loading and error.
 *
 * See the {@link UseCategoryInterface} for a list of methods and values available in this composable.
 *
 * @remarks
 *
 * Under the hood, it calls the following Server Middleware API method:
 *
 * - {@link @vue-storefront/magento-api#categoryList} for loading category list;
 *
 * It is currently used in:
 *
 * - `components/AppHeader.vue`
 *
 * - `components/MobileMenuSidebar.vue`
 *
 * @example
 *
 * Load categories on client side using the `onMounted` Composition API hook:
 *
 * ```vue
 * <template>
 *   <div v-if="loading">
 *     Loading categories…
 *   </div>
 *   <div v-else-if="error.load">
 *     Error: {{ error.load.message }}
 *   </div>
 *   <div v-else>
 *     <!-- Display 'categories' -->
 *   </div>
 * </template>
 *
 * <script>
 * import { onMounted } from '@nuxtjs/composition-api';
 * import { useCategory } from '~/modules/catalog/category/composables/useCategory';
 *
 * export default {
 *   setup() {
 *     const { categories, error, load, loading } = useCategory();
 *
 *     onMounted(async () => {
 *       await load({ pageSize: 10 });
 *     });
 *
 *     return {
 *       error,
 *       loading,
 *       categories,
 *     };
 *   },
 * };
 * </script>
 * ```
 */
export function useCategoryPageInfo(): UseCategoryPageInfoInterface {
  const { app } = useContext();
  const loading: Ref<boolean> = ref(false);
  const error: Ref<UseCategoryPageInfoErrors> = ref({
    load: null,
    loadCategoryMeta: null,
  });
  // const categoriesStore = useCategoryStore();
  // const categories: Ref<Array<CategoryTree>> = ref(null);

  // const load = async (params: UseCategoryPageInfoParamsInput) => {
  //   try {
  //     loading.value = true;
  //     if (categoriesStore.globalCateTree) {
  //       categories.value = categoriesStore.globalCateTree;
  //     } else {
  //       const { data } = await app.context.$vsf.$magento.api.categoryList(params, params?.customQuery ?? null, params?.customHeaders);
  //       categories.value = data?.categories?.items ?? [];
  //       categoriesStore.setGlobalCateTree(categories.value);
  //     }
  //     error.value.load = null;
  //   } catch (err) {
  //     error.value.load = err;
  //     Logger.error('useCategory/load', err);
  //   } finally {
  //     loading.value = false;
  //   }
  // };

  const loadCategoryMeta = async (params: UseCategoryPageInfoMetaParamsInput): Promise<any | null> => {
    let categoryMeta = null;
    let cmsBlock = null;
    let displayMode = null;
    let isShowCms = null;
    let isShowProducts = null;
    let categoryList = null;
    try {
      loading.value = true;
      const { data } = await app.context.$vsf.$magento.api.customQuery({
        query: categoryPageInfoGql,
        queryVariables: {
          filters: {
            category_uid: {
              eq: params.category_uid,
            },
          },
        },
        customHeaders: params?.customHeaders,
      });
      categoryMeta = data.categoryListTopMeta?.items?.[0] || null;
      const category = data?.categoryListTopImage[0] ?? {};
      cmsBlock = category?.cms_block ?? { cmsBlock: { content: '' } };
      displayMode = category?.display_mode ?? displayModesEnum.PRODUCTS;
      isShowCms = displayMode === displayModesEnum.PAGE || displayMode === displayModesEnum.PRODUCTS_AND_PAGE;
      isShowProducts = displayMode === displayModesEnum.PRODUCTS || displayMode === displayModesEnum.PRODUCTS_AND_PAGE;
      categoryList = data?.categoryList;

      error.value.loadCategoryMeta = null;
    } catch (err) {
      error.value.loadCategoryMeta = err;
      Logger.error('useCategory/loadCategoryMeta', err);
    } finally {
      loading.value = false;
    }
    return { categoryMeta, cmsBlock, displayMode, isShowCms, isShowProducts, categoryList };
  };

  return {
    // load,
    loadCategoryMeta,
    loading,
    error,
    // categories,
  };
}

export * from './useCategoryPageInfo';

export default useCategoryPageInfo;
