import { VsfContext } from '~/composables/context';
import { ComposableFunctionArgs } from '~/composables/types';
import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';

export const loadCartCommand = {
  execute: async (context: VsfContext, params: ComposableFunctionArgs<{
    realCart?: boolean;
  }>) => {
    const apiState = context.$magento.config.state;
    Logger.debug('[Magento Storefront]: loadCartCommand Loading Cart');
    const customerToken = apiState.getCustomerToken();
    const virtual = !params.realCart;
    const locale = apiState.getLocale();
    const createVirtualCart = () => (null as Cart);

    const createRealCart = async (): Promise<string> => {
      Logger.debug('[Magento Storefront]: useCart.load.createNewCart');

      apiState.setCartId();

      const { data } = await context.$magento.api.createEmptyCart();
      Logger.debug('[createNewCart Result]:', { data });

      apiState.setCartId(data.createEmptyCart);

      return data.createEmptyCart;
    };

    const getCartData = async (id: string) => {
      console.info('[Magento Storefront]: useCart.load.getCartData ID->', id);

      const cartQueryString = locale === 'cn' ? 'cart-query-toal-cn' : 'cart-query-toal';
      console.info('*** [load cart -> cartQueryString:', cartQueryString);
      const { data, errors } = await context.$magento.api.cart(id, {
        cart: cartQueryString,
      });
      // console.info('>>> [Api.cart Result]:', JSON.stringify(data));

      if (!data?.cart || errors?.length) {
        console.error('!!! [Api GetCartData Error:]', errors[0]);
        throw errors[0] ?? new Error('Can not find your cart data');
      }

      data.cart.items = data.cart.items.filter(Boolean);
      return data.cart as unknown as Cart;
    };

    const getCart = async (virtualCart: boolean, cartId?: string) => {
      if (!cartId) {
        console.warn('*** not find cartID ***');
        if (virtualCart) {
          return createVirtualCart();
        }

        // eslint-disable-next-line no-param-reassign
        cartId = await createRealCart();
        apiState.setCartId(cartId);
      }

      return getCartData(cartId);
    };

    // Try to load cart for existing customer, clean customer token if not possible
    if (customerToken) {
      console.log('*** Has customerToken, start to load Cart ***');
      try {
        // 先获取ID，有则获取详情；没有则先取id再获取详情;
        const currentcartId = apiState.getCartId();
        if (!currentcartId) {
          console.warn('*** No currentcartId ***');
          const { data, errors } = await context.$magento.api.customerCart();
          console.info('>>> [ Get api.customerCart Result]:', JSON.stringify({ data, errors }));

          if (!data?.customerCart || errors?.length) {
            console.error('**Load cart data error:**', JSON.stringify(errors[0]));
            throw errors[0] ?? new Error('Can not find your cart data');
          }
          apiState.setCartId(data.customerCart.id);
          Logger.debug('[*** SetCartID Success:]:', data.customerCart.id);
          // 根据cartID获取详情
        }
        return await getCart(virtual, apiState.getCartId()) as unknown as Cart;
        // --------
        // const { data, errors } = await context.$magento.api.customerCart();
        // Logger.debug('[*** Get api.customerCart Result]:', { data, errors });

        // if (!data?.customerCart && errors?.length) {
        //   throw errors[0];
        // }

        // apiState.setCartId(data.customerCart.id);
        // data.customerCart.items = data.customerCart.items.filter(Boolean);

        // return data.customerCart as unknown as Cart;
      } catch (e) {
        console.error('!!![loadCartCommand Login user Get Cart Error:==]', e);
        // apiState.setCustomerToken();
        throw new Error(e);
      }
    }

    try {
      // If it's not existing customer check if cart id is set and try to load it
      const cartId = apiState.getCartId();
      return await getCart(virtual, cartId);
    } catch {
      apiState.setCartId();
      return await getCart(virtual);
    }
  },
};
